<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />

    <div class="payments-container">
        <!-- <div class="navigation-tabs">
            <div class="nav-links w-full"> -->
        <!-- <NavTabscontainer>
                    <NavTab tabTitle="Plans"></NavTab>
                    <NavTab tabTitle="Invoice"></NavTab>
                </NavTabscontainer> -->
        <!-- <router-link to="/payement" @click="selectTab(0)" :class="{ active: selected[0] }">
                    <font-awesome-icon :icon="['far', 'credit-card']" class="nav-icon" />
                    <span>Plans</span>
                </router-link>
                <router-link to="/invoice" @click="selectTab(1)" :class="{ active: selected[1] }">
                    <font-awesome-icon :icon="['fas', 'chart-bar']" class="nav-icon" />
                    <span>Invoice</span>
                </router-link>
            </div> -->
        <!---- <div class="searchbar">
                <img loading="lazy"  decoding="async" src="../assets/Images/icons/search-icon.svg" alt="search-icon" />
                <input type="text" placeholder="Search Tasks" />
            </div>-->
        <!-- </div> -->
        <PayementControl :show="show" :toggleModal="toggleModal" />
        <div class="grid-row-1">
            <div class="update-credit-card">
                <div>
                    <h1>Update your Credit Card</h1>
                    <p>Update your card for workflow with no hidden fees. Hold and spend what you can.</p>
                    <button>Update</button>
                </div>
                <div class="credit-card">
                    <img loading="lazy" decoding="async" src="../assets/Images/credit-card.jpg" alt="credit-card" />
                </div>
            </div>
            <div class="control-credit-card">
                <img loading="lazy" decoding="async" src="../assets/Images/icons/fingerprint.svg" alt="finger-print" />
                <h1>Control card spending in-app with a tap</h1>
                <p>Control your card, with one tap.</p>
                <p>Spend what you can.</p>
                <button class="strech" @click="toggleModal">Cards</button>
            </div>
            <div class="w-full row-start-2 col-span-2 bg-white rounded-2xl flex flew-row justify-between gap-10 p-20 items-center relative">
                <label class="font-bold text-2xl" for="order_id">{{ $t("Discounts") }}:</label>
                <input type="text" id="order_id" v-model="discount" class="h-12 shadow-[0_0_3px_2px_rgba(0,0,0,10%)] w-[50%] rounded-full p-2" />
                <div class="noCode" v-if="noCode">{{ $t("no code") }}</div>
                <button class="font-bold text-2xl btn" v-if="!isLoading" @click="applyDiscount">{{ $t("Get Now") }}</button>
                <button class="font-bold text-2xl btn" v-else @click="applyDiscount">
                    <LoaderComponent />
                </button>
            </div>
        </div>
        <!-- <div class="flex-row">
            <div class="plans-header">
                <h1 class="plans-and-pricing">Plans & Pricing</h1>
                <p class="plans-text">Whether your time-saving automation needs are large or small, we're here to help you scale.</p>
            </div>
            <div v-if="activeMonthly" class="plans-switch">
                <button @click="setActiveMonthly" class="plans-button active">MONTHLY</button>
                <button @click="setActiveYearly" class="plans-button">YEARLY<span class="discount">-25%</span></button>
            </div>
            <div v-if="activeYearly" class="plans-switch">
                <button @click="setActiveMonthly" class="plans-button">MONTHLY</button>
                <button @click="setActiveYearly" class="plans-button active">YEARLY<span class="discount discount-active">-25%</span></button>
            </div>
        </div> -->

        <section class="pricing-panel">
            <div class="header">
                <h1>{{ $t("Plans and pricing") }}</h1>
                <h4>{{ $t("Choose the perfect plan for your business needs") }}</h4>
            </div>
            <div class="saving-container">
                <div class="save-span">
                    <span>{{ $t("Save 15%") }}</span> {{ $t("on monthly plan!") }}
                </div>
                <div class="time-container">
                    <button class="month" :class="Plan === 'month' ? 'before:translate-x-[0] !text-white ' : 'before:translate-x-[100%] !text-[#a1a1aa]'" @click="SelectPlan('month')">
                        {{ $t("Monthly") }}
                    </button>
                    <button class="year" :class="Plan === 'year' ? ' !text-white' : ' !text-[#a1a1aa]'" @click="SelectPlan('year')">{{ $t("Yearly") }}</button>
                </div>
            </div>
            <div class="pricing-cards-wrapper">
                <div class="pricing-card">
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Free") }}</div>
                            <div class="h5">{{ $t("Best for personal use") }}</div>
                        </div>
                        <div class="price-value"><span>$0</span>{{ $t("/month") }}</div>
                    </div>
                    <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("What you get:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                {{ $t("User") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                {{ $t("5 Tests per Assessment") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >10 {{ $t("Assessments") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Reporting and analytics") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("What you get") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-card">
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Basic") }}</div>
                            <div class="h5">{{ $t("Best for Small Business") }}</div>
                        </div>
                        <div class="price-value">
                            <span>${{ Plan === "year" ? 84 : 99 }}</span
                            >{{ $t("/month") }}
                        </div>
                    </div>
                    <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("Upgraded features:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                3 {{ $t("Users") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("5 Tests per Assessment") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >50 {{ $t("Assessments") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Reporting and analytics") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Customer Support") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-card pro">
                    <div class="popular-span">
                        {{ $t("Most Popular") }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clip-path="url(#clip0_4754_17971)">
                                <path
                                    d="M6.49994 16.0009C6.34193 16.0017 6.18743 15.9543 6.0571 15.8649C5.92676 15.7756 5.8268 15.6486 5.77057 15.5009L4.52557 12.2634C4.50035 12.1982 4.46178 12.1389 4.41232 12.0894C4.36286 12.04 4.30362 12.0014 4.23838 11.9762L0.999941 10.7303C0.852423 10.6736 0.725541 10.5736 0.63604 10.4434C0.546538 10.3132 0.498626 10.1589 0.498626 10.0009C0.498626 9.84288 0.546538 9.68859 0.63604 9.55837C0.725541 9.42815 0.852423 9.32813 0.999941 9.27152L4.23744 8.02652C4.30268 8.0013 4.36193 7.96273 4.41138 7.91327C4.46084 7.86381 4.49941 7.80457 4.52463 7.73933L5.77057 4.50089C5.82718 4.35337 5.9272 4.22649 6.05742 4.13699C6.18764 4.04749 6.34193 3.99958 6.49994 3.99958C6.65795 3.99958 6.81225 4.04749 6.94246 4.13699C7.07268 4.22649 7.1727 4.35337 7.22932 4.50089L8.47432 7.73839C8.49953 7.80363 8.5381 7.86288 8.58756 7.91233C8.63702 7.96179 8.69627 8.00036 8.7615 8.02558L11.9803 9.26402C12.1338 9.32092 12.266 9.42375 12.359 9.55851C12.452 9.69327 12.5012 9.85342 12.4999 10.0171C12.4976 10.1724 12.4486 10.3234 12.3595 10.4505C12.2703 10.5776 12.1451 10.6751 11.9999 10.7303L8.76244 11.9753C8.6972 12.0005 8.63795 12.0391 8.5885 12.0885C8.53904 12.138 8.50047 12.1972 8.47525 12.2625L7.22932 15.5009C7.17308 15.6486 7.07312 15.7756 6.94279 15.8649C6.81245 15.9543 6.65796 16.0017 6.49994 16.0009Z"
                                    fill="white"
                                />
                                <path
                                    d="M2.74994 5.50089C2.6573 5.50089 2.56684 5.47282 2.49047 5.42038C2.4141 5.36794 2.35541 5.2936 2.32213 5.20714L1.79525 3.83714C1.78383 3.80718 1.76621 3.77997 1.74354 3.75729C1.72086 3.73462 1.69365 3.717 1.66369 3.70558L0.293691 3.1787C0.207247 3.14541 0.132915 3.08672 0.0804885 3.01035C0.0280618 2.93398 0 2.84352 0 2.75089C0 2.65826 0.0280618 2.5678 0.0804885 2.49143C0.132915 2.41506 0.207247 2.35637 0.293691 2.32308L1.66369 1.7962C1.69362 1.78473 1.72081 1.76709 1.74347 1.74442C1.76614 1.72176 1.78378 1.69457 1.79525 1.66464L2.31744 0.306828C2.34689 0.226891 2.39757 0.156491 2.46402 0.103193C2.53048 0.0498954 2.6102 0.0157158 2.69463 0.00432764C2.79599 -0.00799417 2.89856 0.0138741 2.98608 0.0664649C3.0736 0.119056 3.14105 0.199359 3.17775 0.29464L3.70463 1.66464C3.7161 1.69457 3.73374 1.72176 3.75641 1.74442C3.77908 1.76709 3.80626 1.78473 3.83619 1.7962L5.20619 2.32308C5.29263 2.35637 5.36697 2.41506 5.41939 2.49143C5.47182 2.5678 5.49988 2.65826 5.49988 2.75089C5.49988 2.84352 5.47182 2.93398 5.41939 3.01035C5.36697 3.08672 5.29263 3.14541 5.20619 3.1787L3.83619 3.70558C3.80623 3.717 3.77902 3.73462 3.75635 3.75729C3.73367 3.77997 3.71605 3.80718 3.70463 3.83714L3.17775 5.20714C3.14447 5.2936 3.08578 5.36794 3.00941 5.42038C2.93304 5.47282 2.84258 5.50089 2.74994 5.50089Z"
                                    fill="white"
                                />
                                <path
                                    d="M12.4999 8.00089C12.3989 8.00086 12.3002 7.9702 12.2169 7.91297C12.1336 7.85573 12.0696 7.7746 12.0334 7.68027L11.3196 5.82495C11.3071 5.79226 11.2878 5.76257 11.263 5.73781C11.2383 5.71304 11.2086 5.69376 11.1759 5.6812L9.32057 4.96745C9.22631 4.93113 9.14526 4.86712 9.0881 4.78383C9.03094 4.70055 9.00034 4.6019 9.00034 4.50089C9.00034 4.39988 9.03094 4.30123 9.0881 4.21795C9.14526 4.13466 9.22631 4.07065 9.32057 4.03433L11.1759 3.32058C11.2086 3.30802 11.2383 3.28874 11.263 3.26397C11.2878 3.23921 11.3071 3.20952 11.3196 3.17683L12.0281 1.33464C12.0604 1.24753 12.1158 1.17081 12.1882 1.11264C12.2607 1.05448 12.3476 1.01705 12.4396 1.00433C12.5502 0.990939 12.6621 1.01488 12.7576 1.07234C12.8531 1.1298 12.9266 1.2175 12.9665 1.32152L13.6803 3.17683C13.6928 3.20952 13.7121 3.23921 13.7369 3.26397C13.7616 3.28874 13.7913 3.30802 13.824 3.32058L15.6793 4.03433C15.7736 4.07065 15.8546 4.13466 15.9118 4.21795C15.9689 4.30123 15.9995 4.39988 15.9995 4.50089C15.9995 4.6019 15.9689 4.70055 15.9118 4.78383C15.8546 4.86712 15.7736 4.93113 15.6793 4.96745L13.824 5.6812C13.7913 5.69376 13.7616 5.71304 13.7369 5.73781C13.7121 5.76257 13.6928 5.79226 13.6803 5.82495L12.9665 7.68027C12.9302 7.7746 12.8663 7.85573 12.783 7.91297C12.6997 7.9702 12.601 8.00086 12.4999 8.00089Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_4754_17971">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Pro") }}</div>
                            <div class="h5">{{ $t("Best for Staffing Companies") }}</div>
                        </div>
                        <div class="price-value">
                            <span>${{ Plan === "year" ? 250 : 295 }}</span
                            >{{ $t("/month") }}
                        </div>
                    </div>
                    <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("Evolved Features:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                5 {{ $t("Users") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("5 Tests per Assessment") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >300 {{ $t("Assessments") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Talent Marketplace") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Calender Integration") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pricing-card">
                    <div class="pricing-header">
                        <div class="pricing-type">
                            <div class="h2">{{ $t("Enterprise") }}</div>
                            <div class="h5">{{ $t("Best for personal use") }}</div>
                        </div>
                        <div class="price-value">
                            <span>{{ $t("Custom") }}</span>
                        </div>
                    </div>
                    <button class="pricing-card-button">{{ $t("Get Started") }}</button>
                    <div class="pricing-features">
                        <div class="h5">{{ $t("Unlimited features:") }}</div>
                        <div class="infos-list">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    />
                                </svg>
                                {{ $t("Unlimited Users") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("5 Tests per Assessment") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Unlimited Assessments") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Automation") }}
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9556 5.90211L9.05063 19.5078L3.04199 13.4991L4.5004 12.0407L8.95017 16.4905L19.4034 4.54395L20.9556 5.90211Z"
                                        fill="#1D4ED8"
                                    /></svg
                                >{{ $t("Talent Marketplace") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="learn-more">
                <h1 @click="showMore" v-if="!isShown">
                    <span>{{ $t("Click here") }}</span> {{ $t("To Compare Plans") }}
                </h1>
            </div> -->
        </section>
    </div>
</template>

<script>
import PayementControl from "../components/PayementControl.vue";
// import NavTab from "@/components/NavTab.vue";
// import NavTabscontainer from "@/components/NavTabscontainer.vue";
import { useStore } from "../store/index";
import LoaderComponent from "@/components/LoaderComponent.vue";
import ToastNotification from "@/components/ToastNotification";
import axios from "axios";

export default {
    name: "PayementView",
    components: {
        PayementControl,
        LoaderComponent,
        ToastNotification,
        // NavTab, NavTabscontainer
    },
    data() {
        return {
            show: false,
            activeMonthly: true,
            activeYearly: false,
            selected: [true, false],
            current: 0,
            Plan: "month",
            discount: "",
            noCode: false,
            isLoading: false,
            isVisible: false,
            message: "",
            bgc: "",
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        toggleModal: function () {
            this.show = !this.show;
        },
        setActiveMonthly: function () {
            this.activeMonthly = true;
            this.activeYearly = false;
        },
        setActiveYearly: function () {
            this.activeYearly = true;
            this.activeMonthly = false;
        },
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
        SelectPlan(plan) {
            this.Plan = plan;
        },

        applyDiscount() {
            if (this.discount === "") {
                this.noCode = true;
                return;
            }
            this.noCode = false;
            this.isLoading = true;
            let data = JSON.stringify({
                discount: this.discount,
            });

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://server.go-platform.com/company/updateCredit",
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
                data: data,
            };

            axios
                .request(config)
                .then((response) => {
                    this.Store.getCompanyCredit();
                    this.isLoading = false;
                    this.bgc = "success";
                    this.message = response.data.message;
                    this.isVisible = true;
                    setTimeout(() => {
                        this.isVisible = false;
                    }, 5000);
                })
                .catch((error) => {
                    console.log({ error });
                    this.isLoading = false;
                    this.bgc = "red";
                    this.message = error?.response.data;
                    this.isVisible = true;
                    setTimeout(() => {
                        this.isVisible = false;
                    }, 5000);
                });
        },
    },
};
</script>

<style scoped lang="scss">
button:hover {
    opacity: 0.85;
}

.payments-container {
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.grid-row-1 {
    display: grid;
    grid-template-columns: 6fr 4fr;
    grid-template-rows: 4fr 2fr;
    gap: 16px;
    padding: 24px;

    button {
        color: white;
    }
}

.grid-row-1 > div {
    background: #fff;
    padding: 16px;
    border-radius: 20px;
}

.update-credit-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    align-items: center;
}

.update-credit-card > div > h1 {
    font-weight: 700;
    font-size: 34px;
    color: #1b2559;
}

.update-credit-card > div > p {
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #a3aed0;
}

.credit-card {
    margin: -16px;
}

.credit-card > img {
    width: 100%;
}

.control-credit-card {
    // margin-right: 48px;
}

.control-credit-card > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #1b2559;
}

.control-credit-card > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #a3aed0;
}

.strech {
    display: block;
    width: 100%;
    margin-top: 24px;
}

.flex-row {
    display: flex;
    align-items: center;
}

.plans-header {
    padding: 0px 48px;
    width: 50%;
}

.plans-and-pricing {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: #2196f3;
}

.plans-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #848199;
}

.plans-switch {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 22px;
}

.plans-button {
    all: unset;
    padding: 8px 16px;
    color: #3b3472;
    font-weight: 500;
    font-size: 12px;
}

.flex-row .active {
    background: #2196f3;
    box-shadow: 0px 5px 7px rgba(82, 67, 194, 0.230196);
    border-radius: 22px;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
}

.discount {
    color: rgba(243, 10, 10, 0.8);
    font-weight: 500;
    font-size: 12px;
    margin-left: 5px;
}

.discount-active {
    color: #fff;
}

.payement-plans {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 26px;
    margin: 64px;
    //   overflow: hidden;
    gap: 20px;
}

.plan {
    padding: 48px;
    background: #fff;
    border-radius: 26px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    img {
        border-radius: 50%;
        // background-color: #c293fe;
    }

    &:hover {
        color: white;
        background: linear-gradient(340.25deg, #2196f3 2.62%, #fab2ff 97.66%);
        box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.295755);
        border-radius: 26px;
        transform: scale(1.05);
        z-index: 9 !important;

        button {
            color: #fff;
            background: #c293fe;
            opacity: 1;
        }

        span {
            color: #fff;
        }

        img {
            background-color: #ffffff;
        }
    }
}

.price {
    font-weight: 700;
    font-size: 36px;
    color: #231d4f;
}

.per-month {
    font-weight: 500;
    font-size: 17px;
    color: #848199;
}

.plan-title {
    display: block;
    font-weight: 500;
    font-size: 28px;
    color: #231d4f;
}

.plan-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #848199;
    display: block;
    margin-bottom: 24px;
}

button {
    background: #2196f3;
    border-radius: 70px;
    padding: 12px 56px;
    border: none;
    // color: #fff;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.advantage {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.advantage > img {
    margin-right: 8px;
}

.advantage > span {
    font-weight: 500;
    font-size: 15px;
    color: #848199;
}

.choose-button {
    all: unset;
    padding: 8px 24px;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    background: #231d4f;
    opacity: 0.5;
    border-radius: 24px;
}

#active {
    background: linear-gradient(340.25deg, #2196f3 2.62%, #fab2ff 97.66%);
    box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.295755);
    border-radius: 26px;
    transform: scale(1.2);
    z-index: 9 !important;
}

#active button {
    color: #fff;
    background: #c293fe;
    opacity: 1;
}

#active * {
    color: #fff !important;
}

#active > * {
    transform: scale(0.83);
}

.navigation-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    border-bottom: 2px solid #edeff2;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}

.feature-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 7%;
    width: 75%;
}

.pricing-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;
}

.pricing-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding-bottom: 4rem;
}

.header-two {
    text-align: start;
    width: 75%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
}

.header-two h4 {
    text-align: start;
}

.header {
    text-align: left;
    width: 100%;
    padding: 8px 2rem;
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.header h1 {
    color: var(--sementics-color-fg-default, #18181b);
    /* sementic.type/title/xl */
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 20%;
    width: 100%;
    /* 36.8px */
}

.header h4 {
    color: var(--sementics-color-fg-default, #18181b);
    /* sementic.type/body/xl */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 20px;
    width: 100%;
    /* 22.5px */
}

.feature-table .saving-container {
    width: 85%;
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.pricing-panel .saving-container {
    width: 85%;
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

.feature-table .saving-container {
    width: 85%;
    display: flex;
    align-items: center;
    align-items: flex-end;
    margin-bottom: 20px;
}

// button:hover {
//     opacity: 0.8;
// }

.time-container {
    display: inline-flex;
    padding: 4px;
    align-items: flex-start;
    border-radius: 100px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-primary-on-default, #fff);
    margin-left: 10px;
}

.month {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: transparent;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    position: relative;
    z-index: 5;
    width: 50%;

    /* 16.1px */
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        background: #2196f3;
        left: 0;
        top: 0;
        z-index: -1;
        transition: all 500ms ease-out;
    }
}

.year {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: transparent;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    z-index: 5;
    width: 50%;
    /* 16.1px */
}

.save-span {
    color: #2196f3;
    /* sementic.type/label/s */
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.save-span span {
    color: #2196f3;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 15px */
}

.learn-more {
    margin-top: 3%;
}

.learn-more h1 {
    color: #000;
    /* sementic.type/title/xl */
    font-family: Roboto;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 20%;
    text-align: start;
    cursor: pointer;

    &:hover span {
        text-decoration: underline;
        color: #2196f3;
    }
}

.learn-more h1 span {
    color: #000;
    /* sementic.type/title/xl */
    font-family: Roboto;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 20%;
    text-align: start;
}

.price-value {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/s */
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    /* 13.8px */
}

.price-value span {
    color: var(--sementics-color-fg-default, #18181b);

    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    /* 36.8px */
}

.pricing-cards-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.pricing-cards-wrapper button {
    background: #2196f3;
}

.blank-container {
    width: 25%;
}

.small-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 75%;
}

.small-wrapper button {
    background: #2196f3;
}

.cards-features {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.pricing-card {
    display: flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-bg-surface, #fff);
    width: 23%;
}

.small-cards {
    display: flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-bg-surface, #fff);
    width: 25%;
    height: auto;
}

.pro {
    border: solid 2px #2196f3;
    position: relative;
    border-radius: 8px;
}

.popular-span svg {
    margin-left: 5px;
}

.popular-span {
    position: absolute;
    width: 100%;
    background: #2196f3;
    border: solid 2px #2196f3;
    border-radius: 5px 5px 0px 0px;

    color: #fff;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.pricing-card-button {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--sementics-color-primary-default, #2563eb);
    color: #fff;
}

.button-enterprise {
    background: #fff !important;
    color: #2196f3;
    border: 1px solid #2196f3;
}

.pricing-header {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.pricing-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.pricing-type .h2 {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/title/l */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    /* 23px */
}

.pricing-type .h5 {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/m */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: start;

    /* 17.5px */
}

.pricing-value {
    display: flex;
    align-items: flex-end;
}

.pricing-features {
    display: flex;
    padding: 0px 16px 16px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    height: auto;
    width: 100%;
}

.pricing-features .h5 {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/label/l */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    text-align: start;
    width: 100%;
    /* 18.4px */
}

.infos-list {
    list-style-type: none;
    align-items: flex-start;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.infos-list div {
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/m */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    width: 100%;
    /* 17.5px */
}

svg {
    margin-right: 10px;
}

.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 98%;
    margin-top: 30px;
}

.tables {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/body/l */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border-bottom: 1px solid var(--sementics-color-border-disabled, #d4d4d8);
}

.header-row {
    border-radius: 8px;
    background: var(--sementics-color-bg-subtle, #e4e4e7);
    width: 100%;
    padding: 12px 10px;
    color: var(--sementics-color-fg-default, #18181b);

    /* sementic.type/title/l */
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    /* 23px */
}

.column {
    width: 20%;
    text-align: center;
}

.column img {
    margin-left: 50%;
    transform: translateX(-50%);
}

.first-column {
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.second-column {
    color: #71717a;
}

.third-column {
    color: #71717a;
}

.fourth-column {
    color: #71717a;
}

.fifth-column {
    color: #2563eb;
}

.table-container img {
    background: #2563eb;
    border-radius: 50%;
    padding: 2px;
}

.text-row .first-column {
    width: 20%;
}

.text-row .second-column {
    width: 80%;
}

@media (max-width: 1322px) {
    .pricing-header {
        height: 114px;
    }

    .pricing-type {
        height: 49px;
    }
}

@media (max-width: 1253px) {
    .small-cards {
        gap: 10px;
        display: flex;
        padding: 24px 16px 16px 16px;
        flex-direction: column;
        align-items: flex-start;

        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
        background: var(--sementics-color-bg-surface, #fff);
        width: 30%;
        height: auto;
    }

    .blank-container {
        display: none;
    }

    .small-wrapper {
        width: 100%;
    }
}

.btn {
    font-size: 20px;
    font-weight: 600;
    width: 30%;
    display: flex;
    justify-content: center;
}

.noCode {
    color: red;
    font-size: 14px;
    position: absolute;
    top: 75%;
    left: 28%;
    transform: translateX(-50%);
}
</style>
